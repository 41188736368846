<template>
  <v-dialog
    v-model="visible"
    scrollable
    persistent
    width="400"
  >
    <v-card
      flat
      tile
      color="white"
      class="rounded"
    >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
      >
        <v-toolbar-title class="text-h6 pl-0">
          {{ title }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-divider />
      <div class="co-flex-col px-4 pt-4">
        <v-text-field
          v-model="wdAlbum.value"
          clearable
          dense
          hide-details
          :label="wdAlbum.label"
          type="text"
          :maxlength="wdAlbum.max"
        >
          <template #append>
            <v-tooltip
              bottom
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  v-on="on"
                  @click="onAdd"
                >
                  {{ wdAlbum.appendIcon }}
                </v-icon>
              </template>
              增加新影集
            </v-tooltip>
          </template>
        </v-text-field>
      </div>
      <v-card-text
        class="pa-3"
        style="height: 300px;"
      >
        <v-list
          color="white"
        >
          <v-list-item-group
            v-model="selected"
            active-class="primary--text"
          >
            <div
              v-for="(album, index) in albums"
              :key="index"
              class="co-flex-col"
            >
              <v-hover v-slot="{ hover }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ album.title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="hover"
                  >
                    <v-icon
                      @click="onDelete(index, album)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-hover>
              <v-divider
                v-if="index < albums.length - 1"
              />
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey darken-3"
          text
          @click="onClose"
        >
          关闭
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onConfirm"
        >
          确定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    data () {
      return {
        visible: false,
        title: '添加到影集',
        wdAlbum: {
          label: '增加一个新影集',
          value: '',
          appendIcon: 'mdi-plus-circle',
          max: 16
        },
        selected: undefined,
        albums: [],
        item: {},
        userInfo: {}
      }
    },
    created () {
      this.userInfo = user.info.get()
    },
    methods: {
      joinAlbum () {
        const me = this
        const album = this.albums[this.selected]

        const executed = function (res) {
          me.hide()
          if (res.status) {
            me.$notify({
              title: '成功提示',
              message: '图片加入影集成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '加入影集失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.albumImg.create({
          data: {
            albumId: album.albumId,
            enabled: web.comm.Enables.ENABLED,
            accessScope: web.comm.Scopes.PUBLIC,
            title: this.item.title,
            imageUrl: this.item.cover
          },
          executed
        })
      },
      onConfirm () {
        // console.log('this.selected: %o', this.selected)
        // console.log('this.item: %o', this.item)
        if (this.selected === undefined) {
          this.$alert('必须选择一个影集', '影集选择提示', {
            confirmButtonText: '好的'
          })
        } else {
          this.joinAlbum()
        }
      },
      onClose () {
        this.hide()
      },
      show (item) {
        this.item = item
        this.getAlbums()
        this.visible = true
      },
      hide () {
        this.visible = false
      },
      getAlbums () {
        let params = null
        params = web.album.getParamsPersonal({
          caches: this.albums
        })
        params.reload = true
        api.httpx.getItems(params)
      },
      onDelete (index, album) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.selected = undefined
            me.albums.splice(index, 1)
          }
        }

        web.album.delete({
          id: album.albumId,
          executed
        })
      },
      onAdd () {
        const me = this
        const title = api.comm.trim(this.wdAlbum.value)
        if (!title) {
          return
        }

        const executed = function (res) {
          if (res.status) {
            me.albums.unshift(res.data)
            me.wdAlbum.value = ''
          }
        }

        web.album.create({
          data: {
            userId: this.userInfo.userId,
            title,
            accessScope: web.comm.Scopes.PUBLIC,
          },
          executed
        })
      }
    }
  }
</script>
